// Boss.ux = {
// 	galeria: {
// 		galeriaMemory: {},
// 		sizes: function(galeria){

// 			if(Boss.getById(galeria)){

// 				var sizes = Boss.screensizes();

// 				var galery = Boss.getById(galeria);
// 				var canvs = Boss.ux.galeria.galeriaMemory[galeria].canvs;
// 				var nav = Boss.ux.galeria.galeriaMemory[galeria].nav;
// 				var side = Boss.ux.galeria.galeriaMemory[galeria].side;

// 				canvs.classList.add('ux-galeria-canvas-hidden');

// 				if(galery.getAttribute('data-full-screen')){

// 					galery.style.width = (sizes.viewWidth)+'px';
// 					galery.style.height = (sizes.viewHeight)+'px';
// 					galery.style.maxWidth = (sizes.viewWidth)+'px';
// 					galery.style.maxHeight = (sizes.viewHeight)+'px';

// 					canvs.style.width = (sizes.viewWidth)+'px';
// 					canvs.style.height = (sizes.viewHeight)+'px';
// 					canvs.style.maxWidth = (sizes.viewWidth)+'px';
// 					canvs.style.maxHeight = (sizes.viewHeight)+'px';

// 					canvs.classList.remove('ux-galeria-canvas-hidden');

// 				}else{

// 					galery.style.width = '';
// 					galery.style.height = '';
// 					galery.style.maxWidth = '';
// 					galery.style.maxHeight = '';

// 					canvs.style.width = '';
// 					canvs.style.height = '';
// 					canvs.style.maxWidth = '';
// 					canvs.style.maxHeight = '';

// 					if(side !== false){

// 						side.style.height = '';
// 						side.style.maxHeight = '';

// 					}

// 					Boss.delay(function(){

// 						var lar = galery.clientWidth;

// 						var alt = (lar / 1.775).toFixed(0);

// 						galery.style.width = lar+'px';
// 						galery.style.height = alt+'px';
// 						galery.style.maxWidth = lar+'px';
// 						galery.style.maxHeight = alt+'px';

// 						canvs.style.width = lar+'px';
// 						canvs.style.height = alt+'px';
// 						canvs.style.maxWidth = lar+'px';
// 						canvs.style.maxHeight = alt+'px';

// 						if(side !== false && sizes.viewWidth > 1023){
// 							side.style.height = alt+'px';
// 							side.style.maxHeight = alt+'px';
// 						}

// 						canvs.classList.remove('ux-galeria-canvas-hidden');

// 					}, 300);
// 				}
// 			}
// 		},
// 		before: function(galeria){

// 			var atual = Boss.ux.galeria.galeriaMemory[galeria].atual;
// 			var total = Boss.ux.galeria.galeriaMemory[galeria].total;
// 			var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;

// 			var legenda = Boss.ux.galeria.galeriaMemory[galeria].legenda;

// 			if((atual - 1) < 0){
// 				atual = (total - 1);
// 			}else{
// 				atual = atual - 1;
// 			}

// 			Boss.ux.galeria.galeriaMemory[galeria].atual = atual;

// 			for(x = 0; x < total; x++){

// 				if(atual === x){

// 					divs[x].classList.remove('hidden');

// 					var im = divs[x].getElementsByTagName('img');
// 					if(im[0]){
// 						if(im[0].getAttribute('title')){
// 							legenda.textContent = im[0].getAttribute('title');
// 						}else{
// 							legenda.textContent = '';
// 						}
// 					}else{
// 						legenda.textContent = '';
// 					}

// 				}else{

// 					divs[x].classList.add('hidden');
// 				}
// 			}
// 		},
// 		after: function(galeria){

// 			var atual = Boss.ux.galeria.galeriaMemory[galeria].atual;
// 			var total = Boss.ux.galeria.galeriaMemory[galeria].total;
// 			var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;

// 			var legenda = Boss.ux.galeria.galeriaMemory[galeria].legenda;

// 			if((atual + 1) < total){
// 				atual = atual + 1;
// 			}else{
// 				atual = 0;
// 				if(Boss.ux.galeria.galeriaMemory[galeria].endSlider){
// 					Boss.ux.galeria.galeriaMemory[galeria].endSlider();
// 				}
// 			}

// 			Boss.ux.galeria.galeriaMemory[galeria].atual = atual;

// 			for(x = 0; x < total; x++){
// 				if(atual === x){
// 					divs[x].classList.remove('hidden');

// 					var im = divs[x].getElementsByTagName('img');
// 					if(im[0]){
// 						if(im[0].getAttribute('title')){
// 							legenda.textContent = im[0].getAttribute('title');
// 						}else{
// 							legenda.textContent = '';
// 						}
// 					}else{
// 						legenda.textContent = '';
// 					}
// 				}else{
// 					divs[x].classList.add('hidden');
// 				}
// 			}
// 		},
// 		goSlide: function(ir, galeria){
// 			Boss.ux.galeria.galeriaMemory[galeria].atual = ir;
// 			Boss.ux.galeria.after(galeria);
// 			Boss.ux.galeria.slider(galeria, true);
// 		},
// 		fullscreen: function(galeria){

// 			var galery = Boss.getById(galeria);
// 			var button_fullscreen = Boss.ux.galeria.galeriaMemory[galeria].button_fullscreen;

// 			if(!galery.getAttribute('data-full-screen')){
// 				galery.setAttribute('data-full-screen', 'true');
// 				galery.classList.add('galeria-full-screen');
// 				button_fullscreen.classList.add('ux-galeria-fullscreen-exit');
// 				button_fullscreen.classList.remove('ux-galeria-fullscreen');

// 				if(Boss.ux.galeria.galeriaMemory[galeria].enterFullScreem){
// 					Boss.ux.galeria.galeriaMemory[galeria].enterFullScreem();
// 				}

// 				if(document.documentElement.requestFullscreen){
// 					document.documentElement.requestFullscreen();
// 				}else if(document.documentElement.mozRequestFullScreen){
// 					document.documentElement.mozRequestFullScreen();
// 				}else if(document.documentElement.webkitRequestFullscreen){
// 					document.documentElement.webkitRequestFullscreen();
// 				}else if(document.documentElement.msRequestFullscreen){
// 					document.documentElement.msRequestFullscreen();
// 				}

// 			}else{
// 				galery.removeAttribute('data-full-screen');
// 				galery.classList.remove('galeria-full-screen');
// 				button_fullscreen.classList.add('ux-galeria-fullscreen');
// 				button_fullscreen.classList.remove('ux-galeria-fullscreen-exit');

// 				if(Boss.ux.galeria.galeriaMemory[galeria].exitFullScreem){
// 					Boss.ux.galeria.galeriaMemory[galeria].exitFullScreem();
// 				}

// 				if(document.exitFullscreen){
// 					document.exitFullscreen();
// 				}else if(document.mozCancelFullScreen){
// 					document.mozCancelFullScreen();
// 				}else if(document.webkitExitFullscreen){
// 					document.webkitExitFullscreen();
// 				}else if(document.msExitFullscreen){
// 					document.msExitFullscreen();
// 				}
// 			}
// 			Boss.delay(function(){
// 				Boss.ux.galeria.sizes(galeria);
// 			}, 100);
// 		},
// 		slider: function(galeria, action){

// 			var galery = Boss.getById(galeria);
// 			var button_slide = Boss.ux.galeria.galeriaMemory[galeria].button_slide;

// 			/* NOACTION */
// 			if(typeof(action) === 'undefined'){

// 				if(galery.getAttribute('data-play') === null){
// 					Boss.ux.galeria.slider(galeria, true);
// 				}else{
// 					Boss.ux.galeria.slider(galeria, false);
// 				}

// 			/* SLIDE SET PLAY */
// 			}else if(action === true){
				
// 				button_slide.classList.add('ux-galeria-slide-pause');
// 				galery.setAttribute('data-play', 'true');

// 				clearInterval(animarGaleria[galeria]);

// 				animarGaleria[galeria] = setInterval(function(){
// 					Boss.ux.galeria.after(galeria);
// 				}, 6000);

// 			/* SLIDE SET PAUSE */
// 			}else if(action === false){

// 				button_slide.classList.remove('ux-galeria-slide-pause');
// 				galery.removeAttribute('data-play');

// 				clearInterval(animarGaleria[galeria]);

// 			}
// 		},
// 		init: function(galeria, fns){

// 			if(Boss.getById(galeria)){

// 				clearInterval(animarGaleria[galeria]);

// 				var galery = Boss.getById(galeria);

// 				var canvs = galery.querySelector('div[data-galeria="canvas"]');

// 				var side = false;
// 				if(Boss.getById(galeria+'-side')){
// 					side = Boss.getById(galeria+'-side');
// 				}

// 				var legenda = galery.querySelector('[data-galeria="nav-legenda"]');

// 				var nav_left = galery.querySelector('[data-galeria="nav-left"]');

// 				var nav_right = galery.querySelector('[data-galeria="nav-right"]');

// 				var button_fullscreen = galery.querySelector('[data-galeria="button-fullscreen"]');

// 				var button_slide = galery.querySelector('[data-galeria="button-slide"]');

// 				Boss.ux.galeria.galeriaMemory[galeria] = {};
// 				Boss.ux.galeria.galeriaMemory[galeria]['atual'] = 0;
// 				Boss.ux.galeria.galeriaMemory[galeria]['canvs'] = canvs;
// 				Boss.ux.galeria.galeriaMemory[galeria]['side'] = side;
// 				Boss.ux.galeria.galeriaMemory[galeria]['legenda'] = legenda;
// 				Boss.ux.galeria.galeriaMemory[galeria]['nav_left'] = nav_left;
// 				Boss.ux.galeria.galeriaMemory[galeria]['nav_right'] = nav_right;
// 				Boss.ux.galeria.galeriaMemory[galeria]['button_fullscreen'] = button_fullscreen;
// 				Boss.ux.galeria.galeriaMemory[galeria]['button_slide'] = button_slide;

// 				if(typeof(fns) === 'object'){
// 					if(typeof(fns.endSlider) === 'function'){
// 						Boss.ux.galeria.galeriaMemory[galeria]['endSlider'] = fns.endSlider;
// 					}
// 					if(typeof(fns.enterFullScreem) === 'function'){
// 						Boss.ux.galeria.galeriaMemory[galeria]['enterFullScreem'] = fns.enterFullScreem;
// 					}
// 					if(typeof(fns.exitFullScreem) === 'function'){
// 						Boss.ux.galeria.galeriaMemory[galeria]['exitFullScreem'] = fns.exitFullScreem;
// 					}
// 				}

// 				Boss.delay(function(){
// 					Boss.ux.galeria.sizes(galeria);
// 				}, 100);

// 				var screensizes = Boss.screensizes();
// 				lastWidth = screensizes.viewWidth;

// 				/* DIVs */
// 				var divs = canvs.getElementsByTagName('div');
// 				var ldivs = divs.length;

// 				Boss.ux.galeria.galeriaMemory[galeria]['total'] = ldivs;
// 				Boss.ux.galeria.galeriaMemory[galeria]['divs'] = divs;

// 				if(ldivs < 2){
// 					nav_left.classList.add('hidden');
// 					nav_right.classList.add('hidden');
// 				}

// 				canvs.classList.add('ux-galeria-canvas-hidden');

// 				/* SHOW FIRST IMAGE */
// 				for(i = 0; i < ldivs; i++){
// 					if(i > 0){
// 						divs[i].classList.add('hidden');
// 					}else{
// 						divs[i].classList.remove('hidden');
// 					}
// 					if(i < 1){
// 						var im = divs[i].getElementsByTagName('img');
// 						if(im[0] && im[0].getAttribute('title')){
// 							legenda.textContent =im[0].getAttribute('title');
// 						}
// 					}
// 				}

// 				var imgs = canvs.getElementsByTagName('img');
// 				var limgs = divs.length;

// 				/* BEFORE IMAGE */
// 				Boss.evts.add(Boss.evtTouchUp(), nav_left, function(evts){
// 					Boss.ux.galeria.before(galeria);
// 					Boss.ux.galeria.slider(galeria, false);

// 					Boss.delay(function(){
// 						Boss.ux.galeria.slider(galeria, true);
// 					}, 20000);
// 				});

// 				/* AFTER IMAGE */
// 				Boss.evts.add(Boss.evtTouchUp(), nav_right, function(evts){
// 					Boss.ux.galeria.after(galeria);
// 					Boss.ux.galeria.slider(galeria, false);

// 					Boss.delay(function(){
// 						Boss.ux.galeria.slider(galeria, true);
// 					}, 20000);
// 				});

// 				/* FULL SCREEN */
// 				Boss.evts.add(Boss.evtTouchUp(), button_fullscreen, function(evts){
// 					Boss.ux.galeria.fullscreen(galeria);
// 				});

// 				/* DRAG */
// 				var start = false;
// 				var end = false;
// 				Boss.evts.add('dragstart', canvs, function(evts){
// 					if(evts.stopPropagation){
// 						evts.stopPropagation();
// 					}
// 					if(evts.preventDefault){
// 						evts.preventDefault();
// 					}
// 				});
// 				Boss.evts.add('mousemove', canvs, function(evts){
// 					if(evts.buttons == 1 && start === false){
// 						start = evts.pageX;
// 					}
// 					if(start !== false){

// 						var at = Boss.ux.galeria.galeriaMemory[galeria].atual;
// 						var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;
// 						var drag = (start - evts.pageX) * -1;
// 						divs[at].style.transform = 'translateX('+drag+'px)';
// 						divs[at].style.opacity = Math.abs(Math.cos(Math.abs(drag) * 2 / Boss.getById(galeria).clientWidth)).toFixed(2);

// 					}
// 					end = evts.pageX;
// 				});
// 				Boss.evts.add('mouseup', canvs, function(evts){
// 					if(start !== false){
// 						var drag = start - end;
// 						if(Math.abs(drag) >= 20){
// 							if(drag > 0){
// 								Boss.ux.galeria.before(galeria);
// 								Boss.ux.galeria.slider(galeria, false);
// 							}else{
// 								Boss.ux.galeria.after(galeria);
// 								Boss.ux.galeria.slider(galeria, false);
// 							}
// 						}
// 					}
// 					var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;
// 					var t = Boss.ux.galeria.galeriaMemory[galeria].total;
// 					for(var x = 0; x < t; x++){
// 						divs[x].removeAttribute('style');
// 					}
// 					start = false;
// 					end = false;
// 				});
// 				Boss.evts.add('mouseout', canvs, function(evts){
// 					if(start !== false){
// 						var drag = start - end;

// 						if(Math.abs(drag) >= 20){
// 							if(drag > 0){
// 								Boss.ux.galeria.before(galeria);
// 								Boss.ux.galeria.slider(galeria, false);
// 							}else{
// 								Boss.ux.galeria.after(galeria);
// 								Boss.ux.galeria.slider(galeria, false);
// 							}
// 						}
// 					}
// 					var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;
// 					var t = Boss.ux.galeria.galeriaMemory[galeria].total;
// 					for(var x = 0; x < t; x++){
// 						divs[x].removeAttribute('style');
// 					}
// 					start = false;
// 					end = false;
// 				});

// 				Boss.evts.add('touchmove', canvs, function(evts){
// 					if(start === false){
// 						start = evts.targetTouches[0].pageX;
// 					}
// 					if(start !== false){

// 						var at = Boss.ux.galeria.galeriaMemory[galeria].atual;
// 						var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;
// 						var drag = (start - evts.targetTouches[0].pageX) * -1;
// 						divs[at].style.transform = 'translateX('+drag+'px)';
// 						divs[at].style.opacity = Math.abs(Math.cos(Math.abs(drag) * 2 / Boss.getById(galeria).clientWidth)).toFixed(2);

// 					}
// 					end = evts.targetTouches[0].pageX;
// 				});
// 				Boss.evts.add('touchend', canvs, function(evts){
// 					if(start !== false){
// 						var drag = start - end;

// 						if(Math.abs(drag) >= 20){
// 							if(drag > 0){
// 								Boss.ux.galeria.before(galeria);
// 								Boss.ux.galeria.slider(galeria, false);
// 							}else{
// 								Boss.ux.galeria.after(galeria);
// 								Boss.ux.galeria.slider(galeria, false);
// 							}
// 						}
// 					}
// 					var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;
// 					var t = Boss.ux.galeria.galeriaMemory[galeria].total;
// 					for(var x = 0; x < t; x++){
// 						divs[x].removeAttribute('style');
// 					}
// 					start = false;
// 					end = false;
// 				});

// 				/* EVENTOS DO TECLADO */
// 				Boss.evts.add('keyup', Boss.getById(galeria), function(evts){

// 					var galery = Boss.getById(galeria);
// 					var button_fullscreen = Boss.ux.galeria.galeriaMemory[galeria].button_fullscreen;

// 					/* ENTER */
// 					if(evts.keyCode === 13 && evts.ctrlKey === true){

// 						Boss.ux.galeria.fullscreen(galeria);

// 					/* ESC */
// 					}else if(evts.keyCode === 27){

// 						if(galery.getAttribute('data-full-screen')){
// 							button_fullscreen.classList.add('ux-galeria-fullscreen');
// 							button_fullscreen.classList.remove('ux-galeria-fullscreen-exit');
// 							galery.removeAttribute('data-full-screen');
// 							galery.classList.remove('galeria-full-screen');
// 						}

// 						Boss.ux.galeria.sizes(galeria);
// 						if(Boss.ux.galeria.galeriaMemory[galeria].exitFullScreem){
// 							Boss.ux.galeria.galeriaMemory[galeria].exitFullScreem();
// 						}

// 					/* LEFT OR SPACE */
// 					}else if(evts.keyCode === 37 || evts.keyCode === 32){

// 						Boss.ux.galeria.before(galeria);
// 						Boss.ux.galeria.slider(galeria, false);

// 						Boss.delay(function(){
// 							Boss.ux.galeria.slider(galeria, true);
// 						}, 20000);

// 					/* RIGHT */
// 					}else if(evts.keyCode === 39){

// 						Boss.ux.galeria.after(galeria);
// 						Boss.ux.galeria.slider(galeria, false);

// 						Boss.delay(function(){
// 							Boss.ux.galeria.slider(galeria, true);
// 						}, 20000);
// 					}
// 				});

// 				/* PLAY PAUSE */
// 				Boss.evts.add(Boss.evtTouchUp(), button_slide, function(evts){
// 					Boss.ux.galeria.slider(galeria);
// 				});

// 			}else{
// 				console.warn('Element not valid!');
// 			}
// 		}
// 	}
// };
