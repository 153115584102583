class Busca {

	constructor(inputs, id_render_busca){

		// Primeiros Atributos, tudo o que a classe for receber
		this.inputs = inputs;
		this.id_render_busca = id_render_busca;
		this.render_element = document.getElementById(this.id_render_busca);
		this.rules(); // Valida esses parametros

		// Atributos internos da  classe
		this.payload = window.location.href.split('payload=')[1];
		this.valores = new Object();
		this.endpoint = '/buscar/buscar?payload=';
		this.endpoint_back = '';

		this.selectmultiples = [];
		this.componentsinputs = [];
		this.instancia_nome;

		for(var i in inputs){
			this.valores[inputs[i]] = '';
		}
		
		/* beforeunload EVENT  */
		Boss.evts.add('popstate', window, function(evts){

			evts.cancelBubble = true;

			if(evts.stopPropagation){
				evts.stopPropagation();
			}

			if(evts.preventDefault){
				evts.preventDefault();
			}

			var payload = window.location.href.split('payload=')[1];

			if(typeof(payloadBusca) !== 'undefined'){
				payloadBusca.setPayload = payload;
				payloadBusca.send(true);
			}
		});
	}

	addComponentsinputs(input){
		this.componentsinputs.push(input);
	}

	addSelectmultiple(selectmultiples){
		this.selectmultiples.push(selectmultiples);
	}

	// Função que valida os parâmetros e da classe
	rules(){

		if(production === true){
			return false;
		}

		if(typeof(this.id_render_busca) == 'undefined'){
			console.warn('Ops, vou precisar que você informe o ID do elemento para renderizar a busca.');
			return false;
		}

		if(!this.render_element){
			console.warn('Ops, parece que o ID elemento que você informou para renderizar a busca não existe.');
			return false;
		}

		if(typeof(this.inputs) == 'undefined'){
			console.warn('Ops, vou precisar que você informe os inputs. É uma lista simples ["dor", "vag", "cod"].');
			return false;
		}
	}

	add(valor, idElemento){

		this.setValueInput(valor, idElemento);

		this.mountPayload();
	}

	set setPayload(payload){
		this.payload = payload;
	}

	zikabraba(){
		var zikabraba = {};
		/* Select Multipl*/
		for (let i = 0; i < payloadBusca.selectmultiples.length; i++) {

			let jsonzada = payloadBusca.selectmultiples[i].values;
			let lista = Object.keys(jsonzada).map(i => JSON.parse(jsonzada[Number(i)]));
			zikabraba[payloadBusca.selectmultiples[i].alias] = lista.join();
		}
		/* Select Inputs*/
		for (let i = 0; i < payloadBusca.componentsinputs.length; i++) {
			if(payloadBusca.componentsinputs[i]){
				var element = payloadBusca.componentsinputs[i];
				if(element.getAttribute('data-alias')){
					var elementvalue = element.value;
					if(elementvalue == 'false'){
						elementvalue = '';
					}
					zikabraba[element.getAttribute('data-alias')] = elementvalue;
				}
			}
		}
		/* Select checkbox */
		/* var checkboxx = Boss.getById('busca-filtros');
		for (let i = 0; i < payloadBusca.checkboxx.length; i++) {
			if(payloadBusca.componentsinputs[i]){
				var element = payloadBusca.componentsinputs[i];
				if(element.getAttribute('data-alias')){
					zikabraba[element.getAttribute('data-alias')] = element.value ? element.value : '';
				}
			}
		} */

		/* Seta os valor denovo */
		payloadBusca.valores = zikabraba;
	}

	setValueInput(valor, idElemento){

		if(typeof(this.valores[idElemento]) !== 'undefined'){

			// Se for um Array || lista
			if(valor instanceof Array){

				this.valores[idElemento] = valor.join(',');
			}else{
			// Se for uma string
				this.valores[idElemento] = valor;
			}
		}

	}

	adicionaValoresPayload(){
		if(typeof(payloadBusca) != 'undefined'){
			var temp = [];
			for (let i = 0; i < payloadBusca.selectmultiples.length; i++) {
				let data = payloadBusca.selectmultiples[i];
				
				temp[i] = [payloadBusca.selectmultiples[i].values,payloadBusca.selectmultiples[i].alias];
				var temp2 = '';
				if(typeof(payloadBusca.selectmultiples[i].values) != 'undefined' && payloadBusca.selectmultiples[i].values != '' && payloadBusca.selectmultiples[i].values){

					for(var y in payloadBusca.selectmultiples[i].values){
						temp2 += payloadBusca.selectmultiples[i].values[y];
					}
					payloadBusca.valores[payloadBusca.selectmultiples[i].alias] = temp2;
				}					
			}
		}
	}

	// Monta o PayLoad
	mountPayload(){

		var temp = '';
		for(var i in this.valores){
			temp += '&' + i + '=' + this.valores[i];
		}
		
		this.payload = temp;
	}

	static checkboxValues(filtros, elemento_pai_filtros){

		if(!elemento_pai_filtros){
			return false;
		}

		var inputs = elemento_pai_filtros.querySelectorAll('input[type="checkbox"]');

		var infs = filtros.inf;

		if(infs !== false){

			for(var i = 0; i < inputs.length; i++){

				inputs[i].checked = false;
				if(infs[inputs[i].id.replace('comp-', '')]){
					inputs[i].checked = true;
				}
			}
		}
	}

	static selectmultiplesValues(filtros, selectmultiples){

		for(var i = 0; i < selectmultiples.length; i++){
			var valor = [];
			if(filtros[selectmultiples[i]['alias']] !== false){
				valor = filtros[selectmultiples[i]['alias']];
			}

			selectmultiples[i].setValues(valor);
		}
	}
	
	static componentinputsValues(filtros, componentsinputs){

		function isDict(v){
			return typeof v==='object' && v!==null && !(v instanceof Array) && !(v instanceof Date);
		}

		for(var i = componentsinputs.length - 1; i >= 0; i--){

			if(filtros[componentsinputs[i].id] !== false){
				var valorInput = '';

				if(isDict(filtros[componentsinputs[i].id])){
					for(var x in filtros[componentsinputs[i].id]){
						valorInput += filtros[componentsinputs[i].id][x] + ',';
						
					}
					
					if(valorInput.substring(valorInput.length-1) == ","){
						valorInput = valorInput.replace(' ','');
						valorInput = valorInput.substr(0, valorInput.length - 1);
					}

				}else{
					valorInput = filtros[componentsinputs[i].id];
				}

				componentsinputs[i].value = valorInput;
			}
		}
	}

	static tituloBusca(titulo_busca_id, quantidade, titulo){

		let quantidadeImoveis = quantidade;
		let titulo_busca = Boss.getById(titulo_busca_id);
		let HTMLtitulo = titulo;
		
		if(titulo_busca){

			if(quantidadeImoveis == 0){
				titulo_busca.innerHTML = 'NENHUM IMÓVEL FOI ENCONTRADO.';
			}else{

				var encontrado = 'ENCONTRADO';
				var imovel = 'IMÓVEL';
				if(quantidadeImoveis > 1){
					encontrado = 'ENCONTRADOS';
					imovel = 'IMÓVEIS';
				}
				titulo_busca.innerHTML = `<strong> ${quantidadeImoveis} ${imovel}</strong> ${encontrado}<br/> <h1 class="detail-line">${HTMLtitulo}</h1>`;
			}
		}
	}

	static positionAtTop(elemento){
		let posicao = 0;
		if(elemento.offsetParent){
			do{
				posicao += elemento.offsetTop;
			} while (elemento = elemento.offsetParent);
		}
		return posicao;
	}

	static request(endpoint, pushstate, selectmultiples, componentsinputs){

		let htmlPreLoader = `
			<div class="box-laterais-1" style="filter: blur(5px) grayscale(30%);">
				<div class="bloco miniatura-imovel iwidth-33-3">
					<div class="miniatura-imovel-img">
						<img src="/jnh/01.jpg" style="top: -10%;"/>
						<div class="miniatura-arrow arrow-left>
							<button class="hidden b b-transparent white" type="button"><i class="icl ic-chevron-left"></i></button>
						</div>
						<div class="miniatura-arrow arrow-right">
							<button class="b b-transparent white"><i class="icl ic-chevron-right"></i></button>
						</div>
					</div>
					<div class="miniatura-imovel-detalhe">
						<div class="miniatura-cidade-estado">
							<i class="ics ic-map-marker-alt"></i> Marau - RS
						</div>
						<div class="miniatura-acao-tipo">
							Comprar Casa Cod: 47
						</div>
						<div class="site-space-1"></div><div class="miniatura-imovel-btns-icons"><div class="inline-block width-33-3 miniatura-informacoes"><span class="left"><i class="icl ic-bed"></i></span><span class="right"><br>02 quartos</span></div><div class="inline-block width-33-3 miniatura-informacoes"><span class="left"><i class="icl ic-car-garage"></i></span><span class="right"><br>02 Vagas</span></div><div class="inline-block width-33-3 miniatura-informacoes"><span class="left"><i class="icl ic-bath"></i></span><span class="right"><br>01 lavabo</span></div></div>
						<div class="site-space-1"></div>
						<div class="inline-block width-75 align-middle">
							<p class="ho-subtitle white "><strong class="miniatura-imovel-preco">Consulte-nos</strong></p>
						</div><div class="inline-block width-25 align-middle text-right">
							<p class="miniatura-p black">Ver Mais</p>
						</div>
						<button title="Adicionar como favorito" class="b b-favorito b-img miniatura-btn-favorito"><img src="/jnh/icones/unlike.png"></button> 
					</div>
				</div><div class="bloco miniatura-imovel iwidth-33-3">
					<div class="miniatura-imovel-img">
						<img src="/jnh/02.jpg" style="top: -10%;"/>
						<div class="miniatura-arrow arrow-left>
							<button class="hidden b b-transparent white" type="button"><i class="icl ic-chevron-left"></i></button>
						</div>
						<div class="miniatura-arrow arrow-right">
							<button class="b b-transparent white"><i class="icl ic-chevron-right"></i></button>
						</div>
					</div>
					<div class="miniatura-imovel-detalhe">
						<div class="miniatura-cidade-estado">
							<i class="ics ic-map-marker-alt"></i> Marau - RS
						</div>
						<div class="miniatura-acao-tipo">
							Comprar Casa Cod: 47
						</div>
						<div class="site-space-1"></div><div class="miniatura-imovel-btns-icons"><div class="inline-block width-33-3 miniatura-informacoes"><span class="left"><i class="icl ic-bed"></i></span><span class="right"><br>02 quartos</span></div><div class="inline-block width-33-3 miniatura-informacoes"><span class="left"><i class="icl ic-car-garage"></i></span><span class="right"><br>02 Vagas</span></div><div class="inline-block width-33-3 miniatura-informacoes"><span class="left"><i class="icl ic-bath"></i></span><span class="right"><br>01 lavabo</span></div></div>
						<div class="site-space-1"></div>
						<div class="inline-block width-75 align-middle">
							<p class="ho-subtitle white "><strong class="miniatura-imovel-preco">Consulte-nos</strong></p>
						</div><div class="inline-block width-25 align-middle text-right">
							<p class="miniatura-p black">Ver Mais</p>
						</div>
						<button title="Adicionar como favorito" class="b b-favorito b-img miniatura-btn-favorito"><img src="/jnh/icones/unlike.png"></button> 
					</div>
				</div><div class="bloco miniatura-imovel iwidth-33-3">
					<div class="miniatura-imovel-img">
						<img src="/jnh/03.jpg" style="top: -10%;"/>
						<div class="miniatura-arrow arrow-left>
							<button class="hidden b b-transparent white" type="button"><i class="icl ic-chevron-left"></i></button>
						</div>
						<div class="miniatura-arrow arrow-right">
							<button class="b b-transparent white"><i class="icl ic-chevron-right"></i></button>
						</div>
					</div>
					<div class="miniatura-imovel-detalhe">
						<div class="miniatura-cidade-estado">
							<i class="ics ic-map-marker-alt"></i> Marau - RS
						</div>
						<div class="miniatura-acao-tipo">
							Comprar Casa Cod: 47
						</div>
						<div class="site-space-1"></div><div class="miniatura-imovel-btns-icons"><div class="inline-block width-33-3 miniatura-informacoes"><span class="left"><i class="icl ic-bed"></i></span><span class="right"><br>02 quartos</span></div><div class="inline-block width-33-3 miniatura-informacoes"><span class="left"><i class="icl ic-car-garage"></i></span><span class="right"><br>02 Vagas</span></div><div class="inline-block width-33-3 miniatura-informacoes"><span class="left"><i class="icl ic-bath"></i></span><span class="right"><br>01 lavabo</span></div></div>
						<div class="site-space-1"></div>
						<div class="inline-block width-75 align-middle">
							<p class="ho-subtitle white "><strong class="miniatura-imovel-preco">Consulte-nos</strong></p>
						</div><div class="inline-block width-25 align-middle text-right">
							<p class="miniatura-p black">Ver Mais</p>
						</div>
						<button title="Adicionar como favorito" class="b b-favorito b-img miniatura-btn-favorito"><img src="/jnh/icones/unlike.png"></button> 
					</div>
				</div>
			</div>
		`;

		if(document.getElementById('titulo_busca')){
			document.getElementById('titulo_busca').innerHTML = 'Aguarde... <i class="icl ic-spinner-third rotating"></i> ';
		}
		if(document.getElementById('render-busca')){
			document.getElementById('render-busca').innerHTML = htmlPreLoader;
		}

		Boss.ajax({
			'url': endpoint,
			'data': {'metodo': 'ajax'},
			'dataType': 'json',
			'done': function(rtn){
				
				loadImages(rtn.paginacao.imagensVisiveis).then(images => activeSetaImagens()).catch(e => console.error('Ops, parece que algo não deu certo', e));
				setimoveisVisiveis(Object.assign(scrollInfinity.imoveisVisiveisMerged, imoveisVisiveis, rtn.paginacao.imoveisVisiveis));
				getImovelImagens();	
				
				
				// Atualiza o título da Busca
				Busca.tituloBusca('titulo_busca', rtn.total, rtn.titulo);
				
				/* Atualiza os campos do SelectMultiple */
				Busca.selectmultiplesValues(rtn.filtros, selectmultiples);
				
				/* Atualiza os campos do Input */
				Busca.componentinputsValues(rtn.filtros, componentsinputs);
				
				/* Atualiza os campos Checkbox */
				Busca.checkboxValues(rtn.filtros, Boss.getById('busca-filtros'));
				
				if(!pushstate){
					history.pushState({}, '', endpoint);
				}
				
				if(document.getElementById('render-busca')){
					document.getElementById('render-busca').innerHTML = rtn.html;
				}
				
				if(typeof(Paginacao) !== 'undefined' && typeof(Paginacao.render) !== 'undefined'){
					Paginacao.setConfiguracao = rtn.paginacao;
					Paginacao.render();

					
					var sc = Busca.positionAtTop(document.getElementById('section-imoveis'));
					if(typeof(smoothScroll) !== 'undefined'){
						smoothScroll.smoothScrollTo(0, sc, 1500);
					}else{
						window.scrollTo(0, sc);
					}
				}
			},
			error: function(rtn){
				Boss.warning({message: 'Falha ao enviar, tente novamente em alguns segundos!'});
			}
		});
	}

	// Função que faz o request para a busca
	// DEPOIS que enviar o payload, fechar a busca && filtro
	// O parametro HREF, é para os casos em que vai utilizar a busca mas não está no controlador da busca aonde fica o render.
	// Isso quer dizer que não podemos utilizar o pushstate porque no retorno do ajax não tem aonde ele exibir os resultados.
	// No formado do site SEM PUSH HISTORY, não é possível alterar a pagina sem um refresh, sendo assim perdendo a instancia da busca.
	// Quando for utilizar o método send() FORA da busca em outro controlador qualquer.. Usa-se send(true, true) para não fazer o request
	// E simplesmente redimencionar para a página da busca com os filtros prontos.
	send(pushstate = false, href = false, time = 800){

		var endpoint = this.endpoint + '' + this.payload;
		var selectmultiples = this.selectmultiples;
		var componentsinputs = this.componentsinputs;

		if(href){
			history.pushState({}, '', endpoint);
			return false;
		}

		Boss.delayPersistent2(function(){
			Busca.request(endpoint, pushstate, selectmultiples, componentsinputs);
		}, time, 'request-ajax');
	}
}