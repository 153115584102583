Boss.ux = {
	galeria: {
		galeriaMemory: {},
		sizes: function(galeria){

			if(Boss.getById(galeria)){

				var sizes = Boss.screensizes();

				var galery = Boss.getById(galeria);
				var canvs = Boss.ux.galeria.galeriaMemory[galeria].canvs;
				var nav = Boss.ux.galeria.galeriaMemory[galeria].nav;
				var side = Boss.ux.galeria.galeriaMemory[galeria].side;

				canvs.classList.add('ux-galeria-canvas-hidden');

				if(galery.getAttribute('data-full-screen')){

					galery.style.width = (sizes.viewWidth)+'px';
					galery.style.height = (sizes.viewHeight)+'px';
					galery.style.maxWidth = (sizes.viewWidth)+'px';
					galery.style.maxHeight = (sizes.viewHeight)+'px';

					canvs.style.width = (sizes.viewWidth)+'px';
					canvs.style.height = (sizes.viewHeight)+'px';
					canvs.style.maxWidth = (sizes.viewWidth)+'px';
					canvs.style.maxHeight = (sizes.viewHeight)+'px';

					canvs.classList.remove('ux-galeria-canvas-hidden');

				}else{

					galery.style.width = '';
					galery.style.height = '';
					galery.style.maxWidth = '';
					galery.style.maxHeight = '';

					canvs.style.width = '';
					canvs.style.height = '';
					canvs.style.maxWidth = '';
					canvs.style.maxHeight = '';

					if(side !== false){

						side.style.height = '';
						side.style.maxHeight = '';

					}

					Boss.delay(function(){

						var lar = galery.clientWidth;

						var alt = (lar / 1.775).toFixed(0);

						galery.style.width = lar+'px';
						galery.style.height = alt+'px';
						galery.style.maxWidth = lar+'px';
						galery.style.maxHeight = alt+'px';

						canvs.style.width = lar+'px';
						canvs.style.height = alt+'px';
						canvs.style.maxWidth = lar+'px';
						canvs.style.maxHeight = alt+'px';

						if(side !== false && sizes.viewWidth > 1023){
							side.style.height = alt+'px';
							side.style.maxHeight = alt+'px';
						}

						canvs.classList.remove('ux-galeria-canvas-hidden');

					}, 300);
				}
			}
		},
		before: function(galeria){

			var atual = Boss.ux.galeria.galeriaMemory[galeria].atual;
			var total = Boss.ux.galeria.galeriaMemory[galeria].total;
			var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;

			var legenda = Boss.ux.galeria.galeriaMemory[galeria].legenda;

			if((atual - 1) < 0){
				atual = (total - 1);
			}else{
				atual = atual - 1;
			}

			Boss.ux.galeria.galeriaMemory[galeria].atual = atual;

			for(x = 0; x < total; x++){

				if(atual === x){

					divs[x].classList.remove('hidden');

					var im = divs[x].getElementsByTagName('img');
					if(im[0]){
						if(im[0].getAttribute('title')){
							legenda.textContent = im[0].getAttribute('title');
						}else{
							legenda.textContent = '';
						}
					}else{
						legenda.textContent = '';
					}

				}else{

					divs[x].classList.add('hidden');
				}
			}
		},
		after: function(galeria){

			var atual = Boss.ux.galeria.galeriaMemory[galeria].atual;
			var total = Boss.ux.galeria.galeriaMemory[galeria].total;
			var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;

			var legenda = Boss.ux.galeria.galeriaMemory[galeria].legenda;

			if((atual + 1) < total){
				atual = atual + 1;
			}else{
				atual = 0;
				if(Boss.ux.galeria.galeriaMemory[galeria].endSlider){
					Boss.ux.galeria.galeriaMemory[galeria].endSlider();
				}
			}

			Boss.ux.galeria.galeriaMemory[galeria].atual = atual;

			for(x = 0; x < total; x++){
				if(atual === x){
					divs[x].classList.remove('hidden');

					var im = divs[x].getElementsByTagName('img');
					if(im[0]){
						if(im[0].getAttribute('title')){
							legenda.textContent = im[0].getAttribute('title');
						}else{
							legenda.textContent = '';
						}
					}else{
						legenda.textContent = '';
					}
				}else{
					divs[x].classList.add('hidden');
				}
			}
		},
		goSlide: function(ir, galeria){
			Boss.ux.galeria.galeriaMemory[galeria].atual = ir;

			Boss.ux.galeria.after(galeria);
			Boss.ux.galeria.slider(galeria, true);
		},
		fullscreen: function(galeria){

			var galery = Boss.getById(galeria);
			var button_fullscreen = Boss.ux.galeria.galeriaMemory[galeria].button_fullscreen;

			if(!galery.getAttribute('data-full-screen')){
				galery.setAttribute('data-full-screen', 'true');
				galery.classList.add('galeria-full-screen');
				button_fullscreen.classList.add('ux-galeria-fullscreen-exit');
				button_fullscreen.classList.remove('ux-galeria-fullscreen');

				if(Boss.ux.galeria.galeriaMemory[galeria].enterFullScreem){
					Boss.ux.galeria.galeriaMemory[galeria].enterFullScreem();
				}

				if(document.documentElement.requestFullscreen){
					document.documentElement.requestFullscreen();
				}else if(document.documentElement.mozRequestFullScreen){
					document.documentElement.mozRequestFullScreen();
				}else if(document.documentElement.webkitRequestFullscreen){
					document.documentElement.webkitRequestFullscreen();
				}else if(document.documentElement.msRequestFullscreen){
					document.documentElement.msRequestFullscreen();
				}

			}else{
				galery.removeAttribute('data-full-screen');
				galery.classList.remove('galeria-full-screen');
				button_fullscreen.classList.add('ux-galeria-fullscreen');
				button_fullscreen.classList.remove('ux-galeria-fullscreen-exit');

				if(Boss.ux.galeria.galeriaMemory[galeria].exitFullScreem){
					Boss.ux.galeria.galeriaMemory[galeria].exitFullScreem();
				}

				if(document.exitFullscreen){
					document.exitFullscreen();
				}else if(document.mozCancelFullScreen){
					document.mozCancelFullScreen();
				}else if(document.webkitExitFullscreen){
					document.webkitExitFullscreen();
				}else if(document.msExitFullscreen){
					document.msExitFullscreen();
				}
			}
			Boss.delay(function(){
				Boss.ux.galeria.sizes(galeria);
			}, 100);
		},
		slider: function(galeria, action){

			var galery = Boss.getById(galeria);
			var button_slide = Boss.ux.galeria.galeriaMemory[galeria].button_slide;

			/* NOACTION */
			if(typeof(action) === 'undefined'){

				if(galery.getAttribute('data-play') === null){
					Boss.ux.galeria.slider(galeria, true);
				}else{
					Boss.ux.galeria.slider(galeria, false);
				}

			/* SLIDE SET PLAY */
			}else if(action === true){
				
				button_slide.classList.add('ux-galeria-slide-pause');
				galery.setAttribute('data-play', 'true');

				clearInterval(animarGaleria[galeria]);

				animarGaleria[galeria] = setInterval(function(){
					Boss.ux.galeria.after(galeria);
				}, 6000);

			/* SLIDE SET PAUSE */
			}else if(action === false){

				button_slide.classList.remove('ux-galeria-slide-pause');
				galery.removeAttribute('data-play');

				clearInterval(animarGaleria[galeria]);

			}
		},
		init: function(galeria, fns){

			if(Boss.getById(galeria)){

				clearInterval(animarGaleria[galeria]);

				var galery = Boss.getById(galeria);

				var canvs = galery.querySelector('div[data-galeria="canvas"]');

				var side = false;
				if(Boss.getById(galeria+'-side')){
					side = Boss.getById(galeria+'-side');
				}

				var legenda = galery.querySelector('[data-galeria="nav-legenda"]');

				var nav_left = galery.querySelector('[data-galeria="nav-left"]');

				var nav_right = galery.querySelector('[data-galeria="nav-right"]');

				var button_fullscreen = galery.querySelector('[data-galeria="button-fullscreen"]');

				var button_slide = galery.querySelector('[data-galeria="button-slide"]');

				Boss.ux.galeria.galeriaMemory[galeria] = {};
				Boss.ux.galeria.galeriaMemory[galeria]['atual'] = 0;
				Boss.ux.galeria.galeriaMemory[galeria]['canvs'] = canvs;
				Boss.ux.galeria.galeriaMemory[galeria]['side'] = side;
				Boss.ux.galeria.galeriaMemory[galeria]['legenda'] = legenda;
				Boss.ux.galeria.galeriaMemory[galeria]['nav_left'] = nav_left;
				Boss.ux.galeria.galeriaMemory[galeria]['nav_right'] = nav_right;
				Boss.ux.galeria.galeriaMemory[galeria]['button_fullscreen'] = button_fullscreen;
				Boss.ux.galeria.galeriaMemory[galeria]['button_slide'] = button_slide;

				if(typeof(fns) === 'object'){
					if(typeof(fns.endSlider) === 'function'){
						Boss.ux.galeria.galeriaMemory[galeria]['endSlider'] = fns.endSlider;
					}
					if(typeof(fns.enterFullScreem) === 'function'){
						Boss.ux.galeria.galeriaMemory[galeria]['enterFullScreem'] = fns.enterFullScreem;
					}
					if(typeof(fns.exitFullScreem) === 'function'){
						Boss.ux.galeria.galeriaMemory[galeria]['exitFullScreem'] = fns.exitFullScreem;
					}
				}

				Boss.delay(function(){
					Boss.ux.galeria.sizes(galeria);
				}, 100);

				var screensizes = Boss.screensizes();
				lastWidth = screensizes.viewWidth;

				/* DIVs */
				var divs = canvs.getElementsByTagName('div');
				var ldivs = divs.length;

				Boss.ux.galeria.galeriaMemory[galeria]['total'] = ldivs;
				Boss.ux.galeria.galeriaMemory[galeria]['divs'] = divs;

				if(ldivs < 2){
					nav_left.classList.add('hidden');
					nav_right.classList.add('hidden');
				}

				canvs.classList.add('ux-galeria-canvas-hidden');

				/* SHOW FIRST IMAGE */
				for(i = 0; i < ldivs; i++){
					if(i > 0){
						divs[i].classList.add('hidden');
					}else{
						divs[i].classList.remove('hidden');
					}
					if(i < 1){
						var im = divs[i].getElementsByTagName('img');
						if(im[0] && im[0].getAttribute('title')){
							legenda.textContent =im[0].getAttribute('title');
						}
					}
				}

				var imgs = canvs.getElementsByTagName('img');
				var limgs = divs.length;

				/* BEFORE IMAGE */
				Boss.evts.add(Boss.evtTouchUp(), nav_left, function(evts){
					Boss.ux.galeria.before(galeria);
					Boss.ux.galeria.slider(galeria, false);

					Boss.delay(function(){
						Boss.ux.galeria.slider(galeria, true);
					}, 20000);
				});

				/* AFTER IMAGE */
				Boss.evts.add(Boss.evtTouchUp(), nav_right, function(evts){
					Boss.ux.galeria.after(galeria);
					Boss.ux.galeria.slider(galeria, false);

					Boss.delay(function(){
						Boss.ux.galeria.slider(galeria, true);
					}, 20000);
				});

				/* FULL SCREEN */
				Boss.evts.add(Boss.evtTouchUp(), button_fullscreen, function(evts){
					Boss.ux.galeria.fullscreen(galeria);
				});

				/* DRAG */
				var start = false;
				var end = false;
				Boss.evts.add('dragstart', canvs, function(evts){
					if(evts.stopPropagation){
						evts.stopPropagation();
					}
					if(evts.preventDefault){
						evts.preventDefault();
					}
				});
				Boss.evts.add('mousemove', canvs, function(evts){
					if(evts.buttons == 1 && start === false){
						start = evts.pageX;
					}
					if(start !== false){

						var at = Boss.ux.galeria.galeriaMemory[galeria].atual;
						var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;
						var drag = (start - evts.pageX) * -1;
						divs[at].style.transform = 'translateX('+drag+'px)';
						divs[at].style.opacity = Math.abs(Math.cos(Math.abs(drag) * 2 / Boss.getById(galeria).clientWidth)).toFixed(2);

					}
					end = evts.pageX;
				});
				Boss.evts.add('mouseup', canvs, function(evts){
					if(start !== false){
						var drag = start - end;
						if(Math.abs(drag) >= 20){
							if(drag > 0){
								Boss.ux.galeria.before(galeria);
								Boss.ux.galeria.slider(galeria, false);
							}else{
								Boss.ux.galeria.after(galeria);
								Boss.ux.galeria.slider(galeria, false);
							}
						}
					}
					var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;
					var t = Boss.ux.galeria.galeriaMemory[galeria].total;
					for(var x = 0; x < t; x++){
						divs[x].removeAttribute('style');
					}
					start = false;
					end = false;
				});
				Boss.evts.add('mouseout', canvs, function(evts){
					if(start !== false){
						var drag = start - end;

						if(Math.abs(drag) >= 20){
							if(drag > 0){
								Boss.ux.galeria.before(galeria);
								Boss.ux.galeria.slider(galeria, false);
							}else{
								Boss.ux.galeria.after(galeria);
								Boss.ux.galeria.slider(galeria, false);
							}
						}
					}
					var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;
					var t = Boss.ux.galeria.galeriaMemory[galeria].total;
					for(var x = 0; x < t; x++){
						divs[x].removeAttribute('style');
					}
					start = false;
					end = false;
				});

				Boss.evts.add('touchmove', canvs, function(evts){
					if(start === false){
						start = evts.targetTouches[0].pageX;
					}
					if(start !== false){

						var at = Boss.ux.galeria.galeriaMemory[galeria].atual;
						var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;
						var drag = (start - evts.targetTouches[0].pageX) * -1;
						divs[at].style.transform = 'translateX('+drag+'px)';
						divs[at].style.opacity = Math.abs(Math.cos(Math.abs(drag) * 2 / Boss.getById(galeria).clientWidth)).toFixed(2);

					}
					end = evts.targetTouches[0].pageX;
				});
				Boss.evts.add('touchend', canvs, function(evts){
					if(start !== false){
						var drag = start - end;

						if(Math.abs(drag) >= 20){
							if(drag > 0){
								Boss.ux.galeria.before(galeria);
								Boss.ux.galeria.slider(galeria, false);
							}else{
								Boss.ux.galeria.after(galeria);
								Boss.ux.galeria.slider(galeria, false);
							}
						}
					}
					var divs = Boss.ux.galeria.galeriaMemory[galeria].divs;
					var t = Boss.ux.galeria.galeriaMemory[galeria].total;
					for(var x = 0; x < t; x++){
						divs[x].removeAttribute('style');
					}
					start = false;
					end = false;
				});

				/* EVENTOS DO TECLADO */
				Boss.evts.add('keyup', Boss.getById(galeria), function(evts){

					var galery = Boss.getById(galeria);
					var button_fullscreen = Boss.ux.galeria.galeriaMemory[galeria].button_fullscreen;

					/* ENTER */
					if(evts.keyCode === 13 && evts.ctrlKey === true){

						Boss.ux.galeria.fullscreen(galeria);

					/* ESC */
					}else if(evts.keyCode === 27){

						/* if(galery.getAttribute('data-full-screen')){
							button_fullscreen.classList.add('ux-galeria-fullscreen');
							button_fullscreen.classList.remove('ux-galeria-fullscreen-exit');
							galery.removeAttribute('data-full-screen');
							galery.classList.remove('galeria-full-screen');
						}

						Boss.ux.galeria.sizes(galeria);
						if(Boss.ux.galeria.galeriaMemory[galeria].exitFullScreem){
							Boss.ux.galeria.galeriaMemory[galeria].exitFullScreem();
						} */

					/* LEFT OR SPACE */
					}else if(evts.keyCode === 37 || evts.keyCode === 32){

						Boss.ux.galeria.before(galeria);
						Boss.ux.galeria.slider(galeria, false);

						Boss.delay(function(){
							Boss.ux.galeria.slider(galeria, true);
						}, 20000);

					/* RIGHT */
					}else if(evts.keyCode === 39){

						Boss.ux.galeria.after(galeria);
						Boss.ux.galeria.slider(galeria, false);

						Boss.delay(function(){
							Boss.ux.galeria.slider(galeria, true);
						}, 20000);
					}
				});

				/* PLAY PAUSE */
				Boss.evts.add(Boss.evtTouchUp(), button_slide, function(evts){
					Boss.ux.galeria.slider(galeria);
				});

			}else{
				console.warn('Element not valid!');
			}
		}
	},
	gallery: {
		mem: {},
		openscreen: function(el){

			if(Boss.getById(el)){

				var gallery = Boss.getById(el);
				gallery.setAttribute('data-full-screen', 'true');
				gallery.classList.add('ui-gallery-full-screen');

				if(Boss.ux.gallery.mem[el].enterFullScreem){
					Boss.ux.gallery.mem[el].enterFullScreem();
				}

				document.body.classList.add('lock');

				if(document.documentElement.requestFullscreen){
					document.documentElement.requestFullscreen();
				}else if(document.documentElement.mozRequestFullScreen){
					document.documentElement.mozRequestFullScreen();
				}else if(document.documentElement.webkitRequestFullscreen){
					document.documentElement.webkitRequestFullscreen();
				}else if(document.documentElement.msRequestFullscreen){
					document.documentElement.msRequestFullscreen();
				}
			}
		},
		exitscreen: function(el){

			if(Boss.getById(el)){

				var gallery = Boss.getById(el);

				gallery.removeAttribute('data-full-screen');
				gallery.classList.remove('ui-gallery-full-screen');

				if(Boss.ux.gallery.mem[el].exitFullScreem){
					Boss.ux.gallery.mem[el].exitFullScreem();
				}

				document.body.classList.remove('lock');

				if(document.exitFullscreen){
					document.exitFullscreen();
				}else if(document.mozCancelFullScreen){
					document.mozCancelFullScreen();
				}else if(document.webkitExitFullscreen){
					document.webkitExitFullscreen();
				}else if(document.msExitFullscreen){
					document.msExitFullscreen();
				}

				window.requestAnimationFrame(function(){
					Boss.ux.gallery.resize(el);
				});
			}
		},
		togglefull: function(el){

			if(Boss.getById(el)){
				var gallery = Boss.getById(el);
				if(!gallery.getAttribute('data-full-screen')){
					this.openscreen(el);
				}else{
					this.exitscreen(el);
				}
			}
		},
		setPhoto: function(el){

			if(Boss.getById(el)){

				if(this.mem[el].canvas && Boss.getById(this.mem[el].canvas)){
					Boss.getById(this.mem[el].canvas).classList.remove('hidden');
				}

				if(this.mem[el].video && Boss.getById(this.mem[el].video)){
					Boss.getById(this.mem[el].video).classList.add('hidden');
				}

				if(this.mem[el].bleft && Boss.getById(this.mem[el].bleft) && this.mem[el].lengthslides > 1){
					Boss.getById(this.mem[el].bleft).classList.remove('hidden');
				}else{
					Boss.getById(this.mem[el].bleft).classList.add('hidden');
				}

				if(this.mem[el].bright && Boss.getById(this.mem[el].bright) && this.mem[el].lengthslides > 1){
					Boss.getById(this.mem[el].bright).classList.remove('hidden');
				}else{
					Boss.getById(this.mem[el].bright).classList.add('hidden');
				}

				if(this.mem[el].play && Boss.getById(this.mem[el].play)){
					Boss.getById(this.mem[el].play).classList.remove('ui-gallery-btn-active');
				}

				if(this.mem[el].photo && Boss.getById(this.mem[el].photo)){
					Boss.getById(this.mem[el].photo).classList.add('ui-gallery-btn-active');
				}

				if(typeof(this.mem[el].playerControl.pauseVideo) !== 'undefined'){
					Boss.ux.gallery.mem[el].playerControl.pauseVideo();
				}
				if(typeof(this.mem[el].playerControl.pause) !== 'undefined'){
					Boss.ux.gallery.mem[el].playerControl.pause();
				}

				if(Boss.getById(this.mem[el].subtitle)){

					var subtitle = Boss.getById(this.mem[el].subtitle);
					subtitle.classList.remove('hidden');

				}
				Boss.ux.gallery.playSlider(el);
			}
		},
		setVideo: function(el){


			if(Boss.getById(el)){

				if(this.mem[el].canvas && Boss.getById(this.mem[el].canvas)){
					Boss.getById(this.mem[el].canvas).classList.add('hidden');
				}
				if(this.mem[el].video && Boss.getById(this.mem[el].video)){
					Boss.getById(this.mem[el].video).classList.remove('hidden');
				}

				if(this.mem[el].bleft && Boss.getById(this.mem[el].bleft)){
					Boss.getById(this.mem[el].bleft).classList.add('hidden');
				}

				if(this.mem[el].bright && Boss.getById(this.mem[el].bright)){
					Boss.getById(this.mem[el].bright).classList.add('hidden');
				}

				if(this.mem[el].play && Boss.getById(this.mem[el].play)){
					Boss.getById(this.mem[el].play).classList.add('ui-gallery-btn-active');
				}

				if(this.mem[el].photo && Boss.getById(this.mem[el].photo)){
					Boss.getById(this.mem[el].photo).classList.remove('ui-gallery-btn-active');
				}

				if(typeof(this.mem[el].playerControl.playVideo) !== 'undefined'){
					Boss.ux.gallery.mem[el].playerControl.playVideo();
				}
				if(typeof(this.mem[el].playerControl.play) !== 'undefined'){
					Boss.ux.gallery.mem[el].playerControl.play();
				}

				if(Boss.getById(this.mem[el].subtitle)){

					var subtitle = Boss.getById(this.mem[el].subtitle);
					subtitle.classList.add('hidden');

				}
				Boss.ux.gallery.stopSlider(el);
			}
		},
		setBackground: function(el){

			if(this.mem[el].background && Boss.getById(this.mem[el].background) && this.mem[el].current > 0){

				var background = Boss.getById(this.mem[el].background);
				var subtitle = Boss.getById(this.mem[el].subtitle);

				for(var ls = 0; ls < this.mem[el].lengthslides; ls++){
					if(ls == (this.mem[el].current - 1)){
						var img = this.mem[el].slides[ls].querySelector('img');
						if(this.mem[el].setBackground === true && img !== null){
							background.style.backgroundImage = 'url('+img.src+')';
						}
						if(subtitle !== null && img !== null && img.getAttribute('alt') && img.getAttribute('alt') !== ''){
							subtitle.textContent = img.getAttribute('alt');
							subtitle.classList.remove('hidden');
						}else{
							subtitle.textContent = '';
							subtitle.classList.add('hidden');
						}
						this.mem[el].slides[ls].classList.remove('hidden');
					}else{
						this.mem[el].slides[ls].classList.add('hidden');
					}
				}

			}
		},
		createId: function(elem){

			var id;
			if(elem !== null){
				if(elem.getAttribute('id')){
					id = elem.getAttribute('id');
				}else{
					id = 'id-'+Math.random().toString(36).substring(2);
					elem.setAttribute('id', id);
				}
			}

			return id;
		},
		before: function(el){

			var current = this.mem[el].current;
			var lengthslides = this.mem[el].lengthslides;

			if((current - 1) < 1){
				current = lengthslides;
			}else{
				current = current - 1;
			}

			this.mem[el].current = current;
			this.setBackground(el);
		},
		after: function(el){

			var current = this.mem[el].current;
			var lengthslides = this.mem[el].lengthslides;

			if((current + 1) <= lengthslides){
				current = current + 1;
			}else{
				current = 1;
			}
			this.mem[el].current = current;
			this.setBackground(el);

		},
		playSlider: function(el){

			if(Boss.getById(el)){

				clearInterval(animarGallery[el]);
				animarGallery[el] = setInterval(function(){
					Boss.ux.gallery.after(el);
				}, Boss.ux.gallery.mem[el].setTime);

			}
		},
		stopSlider: function(el){

			if(Boss.getById(el) && animarGallery[el] !== false){

				clearInterval(animarGallery[el]);

			}
		},
		resize: function(el){

			if(Boss.getById(el)){

				var gallery = Boss.getById(el);
				var width = gallery.querySelector('.ui-gallery-width');
				var canvas = gallery.querySelector('.ui-gallery-canvas');
				var video = gallery.querySelector('.ui-gallery-video');
				var subtitle;

				if(Boss.getById(Boss.ux.gallery.mem[el].subtitle)){
					subtitle = Boss.getById(Boss.ux.gallery.mem[el].subtitle);
				}


				if(canvas !== null){
					width.style.width = '20px';
					width.style.maxWidth = '20px';
					canvas.style.height = '20px';
				}
				if(video !== null){
					video.style.width = '20px';
					video.style.height = '20px';
				}

				/* ALTURA OK */
				if(gallery.clientWidth > (Boss.ux.gallery.mem[el].maxHeight * 1.777777)){

					var h = Math.floor(Boss.ux.gallery.mem[el].maxHeight * 1.777777);
					gallery.classList.remove('ui-gallery-drop');

					if(canvas !== null){
						width.style.width = h+'px';
						width.style.maxWidth = h+'px';
						canvas.style.height = Boss.ux.gallery.mem[el].maxHeight+'px';
					}

					if(video !== null){
						video.style.width = h+'px';
						video.style.height = Boss.ux.gallery.mem[el].maxHeight+'px';
					}

					if(subtitle !== null){
						subtitle.style.top = (Boss.ux.gallery.mem[el].maxHeight - 30)+'px';
					}

				/* CALCULA PELA LARGURA*/
				}else{

					var h = Math.floor(gallery.clientWidth / 1.777777);
					gallery.classList.add('ui-gallery-drop');

					if(canvas !== null){
						width.style.width = gallery.clientWidth+'px';
						width.style.maxWidth = gallery.clientWidth+'px';
						canvas.style.height = h+'px';
					}

					if(video !== null){
						video.style.width = gallery.clientWidth+'px';
						video.style.height = h+'px';
					}

					if(subtitle !== null){
						subtitle.style.top = (h - 30)+'px';
					}
				}
			}
		},
		init: function(el, config){

			if(typeof(config) === 'undefined'){
				var config = {};
			}

			if(Boss.getById(el)){

				clearInterval(animarGallery[el]);

				var maxHeight = false;
				var setBackground = false;
				var setTime = 6000;
				var slides = [];

				if(config.maxHeight){
					maxHeight = config.maxHeight;
				}

				if(config.setBackground && config.setBackground === true){
					setBackground = config.setBackground;
				}

				if(config.setTime && config.setTime > 0){
					setTime = config.setTime * 1000;
				}

				var screensizes = Boss.screensizes();
				lastWidth = screensizes.viewWidth;

				var gallery = Boss.getById(el);

				var background = gallery.querySelector('.ui-gallery-background');

				var width = gallery.querySelector('.ui-gallery-width');
				var canvas = gallery.querySelector('.ui-gallery-canvas');
				var video = gallery.querySelector('.ui-gallery-video');

				var player = gallery.querySelector('.ui-gallery-player');
				var id_player = this.createId(player);

				var full = gallery.querySelector('.ui-gallery-full');

				var photo = gallery.querySelector('.ui-gallery-photo');
				var play = gallery.querySelector('.ui-gallery-play');

				var bleft = gallery.querySelector('.ui-gallery-left');
				var bright = gallery.querySelector('.ui-gallery-right');

				var subtitle = gallery.querySelector('.ui-gallery-subtitle');

				/* PREVENT DRAG */
				var start = false;
				var end = false;
				Boss.evts.add('dragstart', gallery, function(evts){
					if(evts.stopPropagation){
						evts.stopPropagation();
					}
					if(evts.preventDefault){
						evts.preventDefault();
					}
				});

				/* CREATE SUBTITLE */
				if(subtitle === null){

					subtitle = document.createElement('div');
					subtitle.classList.add('ui-gallery-subtitle');
					subtitle.classList.add('hidden');
					width.appendChild(subtitle);
				}

				/* FIND SLIDES */
				if(canvas !== null){

					slides = canvas.querySelectorAll('.ui-gallery-canvas-img');
					var lengthslides = 0;
					var current = 0;

					if(slides.length > 0){
						lengthslides = slides.length;
						current = 1;

						for(var ls = 0; ls < lengthslides; ls++){
							if(ls == 0){
								slides[ls].classList.remove('hidden');
							}else{
								slides[ls].classList.add('hidden');
							}
						}
					}

				}else{
					photo.classList.add('hidden');
				}

				var playerControl;

				this.mem[el] = {
					'background': this.createId(background),
					'slides': slides,
					'lengthslides': lengthslides, 
					'current': current, 
					'canvas': this.createId(canvas),
					'subtitle': this.createId(subtitle),
					'video': this.createId(video),
					'photo': this.createId(photo),
					'play': this.createId(play),
					'bleft': this.createId(bleft),
					'bright': this.createId(bright),
					'maxHeight': maxHeight,
					'playerControl': false,
					'interval': false,
					'setBackground': setBackground,
					'setTime': setTime
				};

				window.requestAnimationFrame(function(){
					Boss.ux.gallery.resize(el);
				});

				if(background !== null){
					Boss.ux.gallery.setBackground(el);
				}

				if(photo !== null){

					Boss.evts.add('click', photo, function(evts){
						Boss.ux.gallery.setPhoto(el);
					});
				}

				if(play !== null){

					Boss.evts.add('click', play, function(evts){
						Boss.ux.gallery.setVideo(el);
					});
				}

				if(full !== null){

					Boss.evts.add('click', full, function(evts){
						Boss.ux.gallery.togglefull(el);
					});
				}

				if(bleft !== null){

					Boss.evts.add('click', bleft, function(evts){
						Boss.ux.gallery.before(el);
						Boss.ux.gallery.stopSlider(el);
					});
				}

				if(bright !== null){

					Boss.evts.add('click', bright, function(evts){
						Boss.ux.gallery.after(el);
						Boss.ux.gallery.stopSlider(el);
					});
				}

				if(lengthslides > 1){
					Boss.evts.add('keydown', document, function(evts){

						/* if(evts.keyCode == 27){
							Boss.ux.gallery.exitscreen(el);
						} */

						/* LEFT OR SPACE */
						if(evts.keyCode === 37 || evts.keyCode === 32){

							Boss.ux.gallery.before(el);
							Boss.ux.gallery.stopSlider(el);

						/* RIGHT */
						}else if(evts.keyCode === 39){

							Boss.ux.gallery.after(el);
							Boss.ux.gallery.stopSlider(el);
						}
					});

					Boss.evts.add('mousemove', canvas, function(evts){
						if(evts.buttons == 1 && start === false){
							start = evts.pageX;
						}
						if(start !== false){

							var current = Boss.ux.gallery.mem[el].current;
							var divs = Boss.ux.gallery.mem[el].slides;
							var drag = (start - evts.pageX) * -1;
							divs[(current - 1)].style.transform = 'translateX('+drag+'px)';
							divs[(current - 1)].style.opacity = Math.abs(Math.cos(Math.abs(drag) * 2 / Boss.getById(el).clientWidth)).toFixed(2);

						}
						end = evts.pageX;
					});
					Boss.evts.add('mouseup', canvas, function(evts){
						if(start !== false){
							var drag = start - end;
							if(Math.abs(drag) >= 20){
								if(drag > 0){

									clearInterval(animarGallery[el]);
									Boss.ux.gallery.before(el);

								}else{
									clearInterval(animarGallery[el]);
									Boss.ux.gallery.after(el);

								}

								Boss.ux.gallery.stopSlider(el);

							}
						}
						var divs = Boss.ux.gallery.mem[el].slides;
						var t = Boss.ux.gallery.mem[el].lengthslides;
						for(var x = 0; x < t; x++){
							divs[x].removeAttribute('style');
						}
						start = false;
						end = false;
					});
					Boss.evts.add('mouseout', canvas, function(evts){
						if(start !== false){
							var drag = start - end;

							if(Math.abs(drag) >= 20){
								if(drag > 0){

									clearInterval(animarGallery[el]);
									Boss.ux.gallery.before(el);

								}else{

									clearInterval(animarGallery[el]);
									Boss.ux.gallery.after(el);

								}
								Boss.ux.gallery.stopSlider(el);
							}
						}
						var divs = Boss.ux.gallery.mem[el].slides;
						var t = Boss.ux.gallery.mem[el].lengthslides;
						for(var x = 0; x < t; x++){
							divs[x].removeAttribute('style');
						}
						start = false;
						end = false;
					});

					Boss.evts.add('touchmove', canvas, function(evts){
						if(start === false){
							start = evts.targetTouches[0].pageX;
						}
						if(start !== false){

							window.requestAnimationFrame(function(){

								var at = Boss.ux.gallery.mem[el].current;
								var divs = Boss.ux.gallery.mem[el].slides;
								var drag = (start - evts.targetTouches[0].pageX) * -1;
								divs[at].style.transform = 'translateX('+drag+'px)';
								divs[at].style.opacity = Math.abs(Math.cos(Math.abs(drag) * 2 / Boss.getById(el).clientWidth)).toFixed(2);

							});

						}
						end = evts.targetTouches[0].pageX;
					});
					Boss.evts.add('touchend', canvas, function(evts){
						if(start !== false){
							var drag = start - end;

							if(Math.abs(drag) >= 20){
								if(drag > 0){

									clearInterval(animarGallery[el]);
									Boss.ux.gallery.before(el);

								}else{

									clearInterval(animarGallery[el]);
									Boss.ux.gallery.after(el);

								}
								Boss.ux.gallery.stopSlider(el);
							}
						}
						var divs = Boss.ux.gallery.mem[el].slides;
						var t = Boss.ux.gallery.mem[el].lengthslides;
						for(var x = 0; x < t; x++){
							divs[x].removeAttribute('style');
						}
						start = false;
						end = false;
					});
				}

				/* RESIZE */
				Boss.evts.add('fakeresize', window, function(evts){

					var screensizes = Boss.screensizes();
					if(lastWidth != screensizes.viewWidth){

						window.requestAnimationFrame(function(){
							Boss.ux.gallery.resize(el);
						});
					}
				});

				gallery.style.overflow = 'visible';


				/* FIND VIDEO */
				if(video === null){

					play.classList.add('hidden');

				}else{

					if(config.video && config.video.id && config.video.type){

						if(config.video.type == 'youtube'){

							var tag = document.createElement('script');
							tag.src = "https://www.youtube.com/player_api";
							var firstScriptTag = document.getElementsByTagName('script')[0];
							firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

							Boss.evts.add('load', tag, function(evts){
								window.onYouTubePlayerAPIReady = function(){
									playerControl = new YT.Player(id_player, {
										height: '360',
										width: '640',
										playerVars: {'autoplay': 0, 'controls': 2, 'rel': 0, 'showinfo': 0},
										videoId: config.video.id,
									});
									Boss.ux.gallery.mem[el].playerControl = playerControl;
								}
							});

							if(typeof(YT) !== 'undefined'){
								playerControl = new YT.Player(id_player, {
									height: '360',
									width: '640',
									playerVars: {'autoplay': 0, 'controls': 2, 'rel': 0, 'showinfo': 0},
									videoId: config.video.id,
								});
								Boss.ux.gallery.mem[el].playerControl = playerControl;
							}

							Boss.ux.gallery.setVideo(el);
						}

						if(config.video.type == 'facebook'){

							Boss.getById(id_player).setAttribute('data-href', config.video.id);
							Boss.getById(id_player).classList.add('fb-video');


							if(typeof(FB) !== 'undefined'){

								FB.XFBML.parse();

								FB.Event.subscribe('xfbml.ready', function(msg){
									if(msg.type === 'video' && msg.id === id_player){
										Boss.ux.gallery.mem[el].playerControl = msg.instance;
									}
								});
							}

							Boss.evts.add('load', Boss.getById('facebook-jssdk'), function(evts){
								FB.Event.subscribe('xfbml.ready', function(msg){
									if(msg.type === 'video' && msg.id === id_player){
										Boss.ux.gallery.mem[el].playerControl = msg.instance;
									}
								});
							});

							Boss.ux.gallery.setVideo(el);
						}
					}else{

						if(play !== null){

							play.classList.add('hidden');
						}

						if(photo !== null){
							photo.classList.add('hidden');
						}

						Boss.ux.gallery.setPhoto(el);
					}
				}
			}
		}
	}
};
