
class Instagram {

    constructor(opts){

        this.defaults = {
            'host': "https://www.instagram.com/",
            'username': '',
            'tag': '',
            'container': '',
            'display_profile': false,
            'display_biography': false,
            'display_gallery': true,
            'display_igtv': false,
            'get_data': false,
            'callback': null,
            'styling': false,
            'items': 8323,
            'items_per_row': 4,
            'margin': 0.5,
            'image_size': 640,
			'mask_miniatura': `<a href="{{url}}" class="block width-20" title="" rel="noopener" target="_blank">
                    	<img class="block width-100 pd-center-inta" src="{{image}}" alt="" />
					</a>`,
			'mask_profile': `<a href="{{profile_pic_url}}"><img src="{{image}}" alt="{{alt}}" /></a>`,
			'mask_biografia': `<h3>{{biography}}</h3>`,
        };
    
        this.image_sizes = {
            "150": 0,
            "240": 1,
            "320": 2,
            "480": 3,
            "640": 4
        };
    
        this.escape_map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            '/': '&#x2F;',
            '`': '&#x60;',
            '=': '&#x3D;'
        };

        this.options = Object.assign({}, this.defaults);
        this.options = Object.assign(this.options, opts);
        this.is_tag = this.options.username == "";

        this.valid = true;
        if (this.options.username == "" && this.options.tag == "") {
            console.error("InstagramFeed: Error, no username or tag defined.");
            this.valid = false;
        } else if (!this.options.get_data && this.options.container == "") {
            console.error("InstagramFeed: Error, no container found.");
            this.valid = false;
        } else if (this.options.get_data && typeof this.options.callback != "function") {
            console.error("InstagramFeed: Error, invalid or undefined callback for get_data");
            this.valid = false;
        }

        if(this.valid){
            this.render();
        }
    }

    escape_string(str){
        return str.replace(/[&<>"'`=\/]/g, char => {
            return this.escape_map[char];
        });
    }

    render(){
        this.get(data => this.options.get_data ? this.options.callback(data) : this.display(data));
    }

    parse_caption(igobj, data){
        if (
            typeof igobj.node.edge_media_to_caption.edges[0] !== "undefined" && 
            typeof igobj.node.edge_media_to_caption.edges[0].node !== "undefined" && 
            typeof igobj.node.edge_media_to_caption.edges[0].node.text !== "undefined" && 
            igobj.node.edge_media_to_caption.edges[0].node.text !== null
        ) {
            return igobj.node.edge_media_to_caption.edges[0].node.text;
        }

        if (
            typeof igobj.node.title !== "undefined" &&
            igobj.node.title !== null &&
            igobj.node.title.length != 0
        ) {
            return igobj.node.title;
        }

        if (
            typeof igobj.node.accessibility_caption !== "undefined" &&
            igobj.node.accessibility_caption !== null &&
            igobj.node.accessibility_caption.length != 0
        ) {
            return igobj.node.accessibility_caption;
        }
        return (this.is_tag ? data.name : data.username) + " image ";
    }

	/* Função responsavel por dar replace na mascaras */
	static replace(string, procurar, substituir){

		let i,
			regex 	= [],
			map 	= {};

		for(i = 0; i < procurar.length; i++){

			regex.push( procurar[i].replace(/([-[\]{}()*+?.\\^$|#,])/g,'\\$1'));
			map[procurar[i]] = substituir[i];
		}

		regex = regex.join('|');

		string = string.replace( new RegExp( regex, 'g' ), function(matched){
			return map[matched];
		});

		return string;
	}

    display(data){

        // Profile
        var html = "";
        if(this.options.display_profile){

            // Mascara Profile
            let find_profile = ["{{alt}}", "{{image}}"];
            let replace_profile = [(this.is_tag ? data.name + " tag pic" : data.username + " profile pic"), data.profile_pic_url];

            html += Instagram.replace(this.options.mask_profile, find_profile, replace_profile);
            
            if (this.is_tag){

                html += "<p class='instagram_tag'><a href='https://www.instagram.com/explore/tags/" + this.options.tag + "' rel='noopener' target='_blank'>#" + this.options.tag + "</a></p>";
            }else{

                html += "<p class='instagram_username'>@" + data.full_name + " (<a href='https://www.instagram.com/" + this.options.username + "' rel='noopener' target='_blank'>@" + this.options.username + "</a>)</p>";
            }

            if(!this.is_tag && this.options.display_biography){
                // Mascara Biografia
                let find_biografia = ["{{biography}}"];
                let replace_biografia = [data.biography];
                
                html += Instagram.replace(this.options.mask_biografia, find_biografia, replace_biografia);
            }
        }

        // Gallery
        if (this.options.display_gallery) {
            var image_index = typeof this.image_sizes[this.options.image_size] !== "undefined" ? this.image_sizes[this.options.image_size] : this.image_sizes[640];

            if (typeof data.is_private !== "undefined" && data.is_private === true) {
                html += "<p class='instagram_private'><strong>This profile is private</strong></p>";
            } else {
                var imgs = (data.edge_owner_to_timeline_media || data.edge_hashtag_to_media).edges;
                max = (imgs.length > this.options.items) ? this.options.items : imgs.length;

                html += "<div class='instagram_gallery'>";

                for (var i = 0; i < max; i++) {
                    var url = "https://www.instagram.com/p/" + imgs[i].node.shortcode,
                        image, type_resource,
                        caption = this.escape_string(this.parse_caption(imgs[i], data));

                    switch (imgs[i].node.__typename) {
                        case "GraphSidecar":
                            type_resource = "sidecar"
                            image = imgs[i].node.thumbnail_resources[image_index].src;
                            break;
                        case "GraphVideo":
                            type_resource = "video";
                            image = imgs[i].node.thumbnail_src
                            break;
                        default:
                            type_resource = "image";
                            image = imgs[i].node.thumbnail_resources[image_index].src;
                    }

                    if (this.is_tag) data.username = '';

                    let find = ["{{url}}", "{{type_resource}}", "{{image}}"];
                    let replace = [url, type_resource, image];
    
                    html += Instagram.replace(this.options.mask_miniatura, find, replace);
                }

                html += "</div>";
            }
        }

        // IGTV
        if (this.options.display_igtv && typeof data.edge_felix_video_timeline !== "undefined") {
            var igtv = data.edge_felix_video_timeline.edges,
                max = (igtv.length > this.options.items) ? this.options.items : igtv.length
            if (igtv.length > 0) {
                html += "<div class='instagram_igtv'>";
                for (var i = 0; i < max; i++) {
                    var url = "https://www.instagram.com/p/" + igtv[i].node.shortcode,
                        caption = this.parse_caption(igtv[i], data);

                    html += "<a href='" + url + "' rel='noopener' title='" + caption + "' target='_blank'>";
                    html += "<img src='" + igtv[i].node.thumbnail_src + "' alt='" + caption + "'" + styles.gallery_image + " />";
                    html += "</a>";
                }
                html += "</div>";
            }
        }

        this.options.container.innerHTML = html;
    }

    get(callback){
        var url = this.is_tag ? this.options.host + "explore/tags/" + this.options.tag + "/" : this.options.host + this.options.username + "/",
            xhr = new XMLHttpRequest();

        var _this = this;
        xhr.onload = function(e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    try{
                        var data = xhr.responseText.split("window._sharedData = ")[1].split("<\/script>")[0];
                    }catch(error){
                        console.error("Instagram Feed: It looks like the profile you are trying to fetch is age restricted. See https://github.com/jsanahuja/InstagramFeed/issues/26");
                        return;
                    }
                    data = JSON.parse(data.substr(0, data.length - 1));
                    data = data.entry_data.ProfilePage || data.entry_data.TagPage;
                    if(typeof data === "undefined"){
                        console.error("Instagram Feed: It looks like YOUR network has been temporary banned because of too many requests. See https://github.com/jsanahuja/jquery.instagramFeed/issues/25");
                        return;
                    }
                    data = data[0].graphql.user || data[0].graphql.hashtag;
                    callback(data, _this);
                } else {
                    console.error("InstagramFeed: Request error. Response: " + xhr.statusText);
                }
            }
        };
        xhr.open("GET", url, true);
        xhr.send();
    }
}